import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InviteService from "../../services/inviteService";
import AuthService from "../../services/autheticationService";
import styles from "./styles.module.css";
import Loader from "./loader";

interface InviteResponse {
    name: string;
    sorteado: boolean;
}

const Sorteio: React.FC = () => {
    const authService = new AuthService();
    const inviteService = new InviteService();
    const [invites, setInvites] = useState<InviteResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    async function logar() {
        try {
            const isAuthenticated = await authService.loginSorteio();
            if (!isAuthenticated) {
                console.error("Login falhou");
                navigate("/login");
            }
        } catch (error) {
            console.error("Erro ao tentar autenticar:", error);
            navigate("/login");
        }
    }

    useEffect(() => {
        async function initialize() {
            await logar();
            await fetchInvites();
        }

        async function fetchInvites() {
            try {
                setLoading(true);
                const response = await inviteService.getInvitesSorteio();
                setInvites(response);
            } catch (error) {
                console.error("Erro ao buscar os convites:", error);
            } finally {
                setLoading(false);
            }
        }

        initialize();
    }, []);

    return (
        <div className={styles.container}>
            <img
                src="https://unirsebr.com.br/wp-content/uploads/2024/12/logo-convite.png"
                alt="Logo do Evento"
                className={styles.logo}
            />
            <h1 className={styles.header}>Sorteio de Convidados</h1>
            {loading ? (
                <Loader />
            ) : (
                <div className={styles.invitesContainer}>
                    <h2 className={styles.subHeader}>Lista de Convidados</h2>
                    <div className={styles.listContainer}>
                        <div className={styles.listColumn}>
                            <ul className={styles.list}>
                                {invites
                                    .filter((_, index) => index % 2 === 0)
                                    .map((invite, index) => (
                                        <li
                                            key={index}
                                            className={`${styles.listItem} ${
                                                invite.sorteado ? styles.listItemSorteado : ""
                                            }`}
                                        >
                                            {invite.name}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        <div className={styles.listColumn}>
                            <ul className={styles.list}>
                                {invites
                                    .filter((_, index) => index % 2 !== 0)
                                    .map((invite, index) => (
                                        <li
                                            key={index}
                                            className={`${styles.listItem} ${
                                                invite.sorteado ? styles.listItemSorteado : ""
                                            }`}
                                        >
                                            {invite.name}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Sorteio;
