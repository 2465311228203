import Header from "../../shared/components/header";
import Footer from "../../shared/components/footer";
import styles from "./style.module.css"
import {useNavigate} from "react-router-dom";
import {useState} from "react";

export default function CustomPrint() {
    const navigate = useNavigate();
    const [guestName, setGuestName] = useState<string>("");
    const [guestRole, setGuestRole] = useState<string>("");

    function capitalizeFirstLetter(name: string) {
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }

    function printar(guestName: string, role: string) {
        const nameParts = guestName.trim().split(' ');
        const firstName = nameParts[0]; // Primeiro nome
        const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : ''; // Último nome, se houver
    
        const printWindow = window.open('', '_blank')!;
        printWindow.document.write(`
        <html>
            <head>
                <style>
                    body {
                        font-family: "Arial Black", Arial, sans-serif;
                        font-size: 34px;
                    }
    
                    h1 {
                        text-align: center;
                        width: 100%;
                        font-size: 34px;
                        margin: 0;
                        line-height: 35px;
                    }
    
                    @page {
                        size: auto;
                        margin: 0mm;
                    }
    
                    .nome {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        height: 100%;
                        width: 100%;
                    }
                </style>
            </head>
            <body>
                <div class="nome">
                    <h1>${capitalizeFirstLetter(firstName)}</h1>
                    <h1>${capitalizeFirstLetter(lastName)}</h1>
                    <h1 style="font-size: 22px; margin-top: 15px">${capitalizeFirstLetter(role)}</h1>
                </div>
            </body>
        </html>`);
        printWindow.document.close();
            printWindow.addEventListener('afterprint', () => {
            printWindow.close();
        });
    
        printWindow.print();
    }
    
    return (
        <>
            <h1>
                <Header/>
                <div className={styles.container}>
                    <h2>Impressão Personalizada</h2>
                    <label className={styles.label}>Nome do convidado:</label>
                    <input className={styles.inpt_guest_name} type="text" onChange={(event) => setGuestName(event.target.value)}/>
                    <label className={styles.label}>Modalidade:</label>
                    <input className={styles.inpt_guest_role} type="text" onChange={(event) => setGuestRole(event.target.value)}/>
                    <button className={styles.btn_print} onClick={() => printar(guestName, guestRole)}>Imprimir</button>
                </div>
                <Footer/>
                <button className={styles.btn_goback} onClick={() => navigate("/painel-operador")}> Voltar</button>
            </h1>
        </>
    );
}