import axios from "axios";
import Invite from "../interfaces/invite";
import IsConsumedResponse from "../interfaces/isConsumedResponse";
import IsValidResponse from "../interfaces/isValidResponse";
import InviteHistoryReport from "../interfaces/inviteHistoryReport";
import Parcial from "../interfaces/parcial";

const URL: string = `${process.env.REACT_APP_URL_API}`;

export interface UserData {
    guest_name: string;
    guest_email: string;
    identificator: string;
    identificator_type: string;
    access_sector: string;
    data: {} | null
}

export default class InviteService {
    axiosInstance = axios.create({
        baseURL: URL,
    });

    async searchByCPF(cpf: string): Promise<Invite> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = localStorage.getItem("event_id");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            cpf = removeCPFMask(cpf);
            const response = await this.axiosInstance.get(
                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&identificator=${cpf}`,
                config
            );
            if (response.status === 204) {
                throw new Error("invite not found");
            }
            return response.data[0];
        } catch (error) {
            throw error;
        }
    }

    async searchByName(nome: string): Promise<Invite[]> {
        nome = cleanName(nome);
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = localStorage.getItem("event_id");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            nome = nome.toUpperCase();
            const response = await this.axiosInstance.get(
                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&name=${nome}`,
                config
            );

            if (response.status === 204) {
                throw new Error("guest not found");
            }

            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async searchByInviteCode(codigo: string): Promise<Invite> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = codigo.startsWith("DMD") ? "a219ab8d-75ad-11ef-8922-62b7865f4827" : localStorage.getItem("event_id");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const response = await this.axiosInstance.get(
                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&guest_code=${codigo}`,
                config
            );

            if (response.status === 204) {
                throw new Error("invite not found");
            }

            return response.data[0];
        } catch (error) {
            throw error;
        }
    }

    async searchByInviteCodeLegado(codigo: string): Promise<Invite> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = "a219ab8d-75ad-11ef-8922-62b7865f4827";
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const response = await this.axiosInstance.get(
                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&guest_code=${codigo}`,
                config
            );

            if (response.status === 204) {
                throw new Error("invite not found");
            }

            return response.data[0];
        } catch (error) {
            throw error;
        }
    }

    async searchInviteHistory(codigo: string): Promise<InviteHistoryReport> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = codigo.startsWith("DMD") ? "a219ab8d-75ad-11ef-8922-62b7865f4827" : localStorage.getItem("event_id");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const response = await this.axiosInstance.get(
                `${URL}/report/brief?company_id=${COMPANY}&event_id=${EVENT}&guest_code=${codigo}`,
                config
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async buscarValidade(codigoQR: string): Promise<IsValidResponse> {
        const SECTOR = localStorage.getItem("access_sector");
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = codigoQR.startsWith("DMD") ? "a219ab8d-75ad-11ef-8922-62b7865f4827" : localStorage.getItem("event_id");
        const OPERADOR = localStorage.getItem("operador");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const body = {
                validated_by: OPERADOR,
                company_id: COMPANY,
                event_id: EVENT,
                guest_code: codigoQR,
                access_sector: SECTOR,
            };
            const response = await this.axiosInstance.put(
                `${URL}/invite/validator`,
                body,
                config
            );
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async consume(codigoQR: string): Promise<IsConsumedResponse> {
        const SECTOR = localStorage.getItem("access_sector");
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = localStorage.getItem("event_id");
        const OPERADOR = localStorage.getItem("operador");

        if (codigoQR.startsWith("DMD")) {
            const nome = (await this.searchByInviteCodeLegado(codigoQR)).guest_name;
            const convite = await this.searchByName(nome).then((response) => response[0]);
            codigoQR = convite.guest_code;
        }

        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const body = {
                validated_by: OPERADOR,
                company_id: COMPANY,
                event_id: EVENT,
                guest_code: codigoQR,
                access_sector: SECTOR,
            };
            const response = await this.axiosInstance.put(
                `${URL}/invite/consumer`,
                body,
                config
            );
            response.data.consumed_at = convertData(response.data.consumed_at);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    async cadastrar(userData: UserData): Promise<Invite> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = localStorage.getItem("event_id");
        try {
            const config = {
                headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
            };
            const body = {
                company_id: COMPANY,
                event_id: EVENT,
                guest_name: userData.guest_name,
                guest_email: userData.guest_email,
                identificator: userData.identificator,
                identificator_type: userData.identificator_type,
                access_sector: userData.access_sector,
                data: userData.data
            };
            const response = await this.axiosInstance.post(
                `${URL}/invite/create`,
                body,
                config
            );

            return this.toIngresso(response.data);
        } catch (error) {
            throw error;
        }
    }

    async createParcial(invites: Invite[]): Promise<Parcial> {
        const invitesUsed = invites.filter((invite) => invite.consumed_at !== undefined);
        const invitesUsedLastHour = invitesUsed.filter((invite) => {
            const consumedAt = new Date(invite.consumed_at);
            const oneHourAgo = new Date();
            oneHourAgo.setHours(oneHourAgo.getHours() - 1);
            return consumedAt > oneHourAgo;
        });

        const accessByCategory: { [key: string]: number } = {};
        invitesUsed.forEach((invite) => {
            const role = invite.data?.role;
            if (role) {
                if (!accessByCategory[role]) {
                    accessByCategory[role] = 0;
                }
                accessByCategory[role]++;
            }
        });

        const accessBySector: { [key: string]: number } = {};
        invitesUsed.forEach((invite) => {
            const sector = invite.access_sector;
            if (sector) {
                if (!accessBySector[sector]) {
                    accessBySector[sector] = 0;
                }
                accessBySector[sector]++;
            }
        });

        const accessByCategoryArray = Object.entries(accessByCategory).map(([role, quantity]) => ({
            role,
            quantity,
        }));

        const accessBySectorArray = Object.entries(accessBySector).map(([sector, quantity]) => ({
            sector,
            quantity,
        }));

        const dados: Parcial = {
            accessedTotal: invitesUsed.length,
            guestsTotal: invites.length,
            totalHora: invitesUsedLastHour.length,
            accessBySector: accessBySectorArray,
            accessByRole: accessByCategoryArray,
        };

        console.log(dados);
        return dados;
    }

    async getInvitesSorteio(): Promise<{ name: string; sorteado: boolean }[]> {
        const COMPANY = "744379fa-d8e0-11ee-96e1-ca42e6fefbe7"
        const EVENT = "5bf24c16-b1be-11ef-9d0f-5a2aedb842b9"
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };
        if(localStorage.getItem("token") === null) {
            return [];
        }
        const listCharacters: string[][] = [
            ["A", "D", "G"],
            ["B", "E", "H"],
            ["C", "F", "I"],
            ["J", "M", "P"],
            ["K", "N", "Q"],
            ["L", "O", "R"],
            ["S", "T", "U"],
            ["V", "W", "X"],
            ["Y", "Z"]
        ];

        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        try {
            const invitePromises: any[] = [];

            for (let i = 0; i < listCharacters.length; i++) {
                const group = listCharacters[i];
                group.forEach((character, index) => {
                    invitePromises.push(
                        (async () => {
                            await delay(i * 1500);
                            console.log(`Requesting data for character: ${character}`);
                            const response = await this.axiosInstance.get(
                                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&name=${character}`,
                                config
                            );
                            console.log(`Response for ${character}:`, response.data);
                            return response.status !== 204 ? response.data : [];
                        })()
                    );
                });
            }

            const responses = await Promise.all(invitePromises);
            const invites = responses.flat();
            const filteredInvites = invites.filter((invite: any) => invite.consumed_at);
            const inviteList = filteredInvites.map((invite: any) => ({
                name: invite.guest_name,
                sorteado: false
            }));
            if (inviteList.length > 0) {
                const randomIndex = Math.floor(Math.random() * inviteList.length);
                inviteList[randomIndex].sorteado = true;
            }
            return inviteList;
        } catch (error) {
            console.error("Error fetching invites:", error);
            throw error;
        }
    }
    async getAllInvites(): Promise<Parcial> {
        const COMPANY = localStorage.getItem("id_company");
        const EVENT = localStorage.getItem("event_id");
        const config = {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        };

        const listCharacters: string[][] = [
            ["A", "D", "G"],
            ["B", "E", "H"],
            ["C", "F", "I"],
            ["J", "M", "P"],
            ["K", "N", "Q"],
            ["L", "O", "R"],
            ["S", "T", "U"],
            ["V", "W", "X"],
            ["Y", "Z"]
        ];

        const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

        try {
            const invitePromises: any[] = [];

            for (let i = 0; i < listCharacters.length; i++) {
                const group = listCharacters[i];
                group.forEach((character, index) => {
                    invitePromises.push(
                        (async () => {
                            await delay(i * 1000);
                            const response = await this.axiosInstance.get(
                                `${URL}/invite/search?company_id=${COMPANY}&event_id=${EVENT}&name=${character}`,
                                config
                            );
                            return response.status !== 204 ? response.data : [];
                        })()
                    );
                });
            }

            const responses = await Promise.all(invitePromises);
            const invites = responses.flat();
            return this.createParcial(invites);

        } catch (error) {
            throw error;
        }
    }


    toIngresso(objeto: any): Invite {
        const ingressoConvertido: Invite = {
            guest_name: objeto.guest_name,
            guest_email: objeto.guest_email,
            identificator: objeto.identificator,
            identificator_type: objeto.identificator_type,
            access_sector: objeto.access_sector,
            PK: "",
            SK: "",
            company_id: objeto.company_id,
            event_id: objeto.event_id,
            guest_code: objeto.guest_code,
            valid_until: objeto.valid_until,
            consumed_at: objeto.consumed_at,
            validated_by: objeto.validated_by,
            data: {
                role: "Fora da lista"
            }
        };
        return ingressoConvertido;
    }
}

function removeCPFMask(cpf: string): string {
    const cpfSemFormatacao = cpf.replace(/\D/g, "");
    return cpfSemFormatacao;
}

function convertData(data: string): string {
    let dataConvertida: string = data.slice(11, 16);
    let horas = dataConvertida.split(":");
    let hora = Number(horas[0]);
    hora -= 3;
    if (hora < 0) hora = 24 + hora;
    dataConvertida = `${hora < 10 ? "0" + hora : hora}:${horas[1]}`;
    return dataConvertida;
}

function cleanName(name: string): string {
    name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    name = name.replace(/\s+/g, " ");
    name = name.toUpperCase();
    return name;
}
