import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";

const funnyPhrases = [
    "Fechando as torneiras...",
    "Cuidando do meio ambiente...",
    "Chamando os embasianos...",
    "Racionando água...",
    "Verificando a sorte...",
];

const Loader: React.FC = () => {
    const [currentPhrase, setCurrentPhrase] = useState<string>("Carregando...");

    useEffect(() => {
        let index = 0;

        const interval = setInterval(() => {
            setCurrentPhrase(funnyPhrases[index]);
            index = (index + 1) % funnyPhrases.length; // Alterna entre as frases
        }, 2000); // Troca a cada 2 segundos

        return () => clearInterval(interval); // Limpa o intervalo ao desmontar o componente
    }, []);

    return (
        <div className={styles.loaderContainer}>
            <div className={styles.loader}></div>
            <p className={styles.loaderText}>{currentPhrase}</p>
        </div>
    );
};

export default Loader;
